import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import { StarIcon as SolidStar } from "@heroicons/react/24/solid";
import {
	BeakerIcon,
	CalendarIcon,
	ChatBubbleBottomCenterIcon,
	ClockIcon,
	StarIcon as OutlineStar,
	UserIcon,
} from "@heroicons/react/24/outline";
import {
	ChatBubbleOvalLeftEllipsisIcon,
	ChatBubbleOvalLeftIcon,
	CalendarIcon as SolidCalendarIcon,
	ChatBubbleBottomCenterIcon as SolidChatBubbleBottomCenterIcon,
} from "@heroicons/react/20/solid";
import {
	ArrowRightIcon,
	ChatBubbleLeftRightIcon,
	CheckIcon,
	ClipboardDocumentIcon,
	PhoneIcon,
	PlusCircleIcon,
	XMarkIcon,
	PencilSquareIcon,
	TrashIcon,
} from "@heroicons/react/16/solid";

import useFetch from "../../shared/hooks/useFetch";
import useCustomConfirm from "../../shared/hooks/useCustomConfirm";

import FormattedPhoneNumber from "../../shared/components/util/FormattedPhoneNumber";
import FormattedDate from "../../shared/components/util/FormattedDate";
import SidePanel from "../../shared/components/UIElements/SidePanel";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import Button from "../../shared/components/FormElements/Button";
import Feed from "../../shared/components/UIElements/Feed";
import Loading from "../../shared/pages/Loading";
import EditPatientPage from "../pages/EditPatientPage";
import AvailabilityDetail from "./AvailabilityDetail";
import SnoozeInfoBox from "./SnoozeInfoBox";

import warningEmoji from "../../assets/Warning.png";
import MiniCopyButton from "../../shared/components/FormElements/MiniCopyButton";

const PatientDetail = ({
	patientId,
	open,
	setOpen,
	reload,
	firstName,
	lastName,
	patientNumber,
	phoneNumber,
	priority,
	onPreviewPage = false,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isEditing, setIsEditing] = useState(false);
	const [patient, setPatient] = useState({
		firstName,
		lastName,
		patientNumber,
		phoneNumber,
		priority,
	});
	const [timeline, setTimeline] = useState([]);
	const [activity, setActivity] = useState([]);

	const getPatient = useFetch();
	const navigate = useNavigate();
	const [confirm, ConfirmModal] = useCustomConfirm();

	const markAsPriority = async () => {
		const response = await getPatient(
			`/api/patients/${patientId}/priority`,
			{ method: "PUT" },
		);

		setPatient((prevPatient) => ({
			...prevPatient,
			priority: !prevPatient.priority,
		}));
		reload();
	};

	const deletePatient = async () => {
		const result = await confirm({
			title: "Weet je het zeker?",
			description:
				"Weet je zeker dat je deze patiënt wilt verwijderen? Dit kan niet ongedaan gemaakt worden",
			cta: "Ja, ik weet het zeker",
			cancel: "Annuleren",
			warning: true,
		});

		if (!result) {
			return;
		}

		await getPatient(`/api/patients/${patientId}`, {
			method: "DELETE",
		});

		toast.success("Patiënt verwijderd");
		reload();
		setOpen(false);
	};

	const createTimeline = (activityData) => {
		if (!patient?.createdAt) {
			return [];
		}

		const events = [
			{
				id: 0,
				content: `Toegevoegd aan Snelterecht`,
				date: patient.createdAt,
				icon: PlusCircleIcon,
				iconBackground: "bg-slate-400",
			},
		];

		// Add activity events if we have any
		if (activityData?.length > 0) {
			activityData.forEach((activity, index) => {
				if (activity.type === "rejection") {
					events.push({
						id: index + 1,
						content: `Uitnodiging geweigerd - kan niet op `,
						href: `/gaten/${activity.slotId}`,
						target: new Date(activity.slotDate).toLocaleDateString(
							"nl-NL",
							{
								month: "short",
								day: "2-digit",
							},
						),
						date: activity.date,
						icon: XMarkIcon,
						iconBackground: "bg-red-500",
					});
				} else if (activity.type === "claim") {
					events.push({
						id: index + 1,
						content: `Uitnodiging geaccepteerd - komt op `,
						href: `/gaten/${activity.slotId}`,
						target: new Date(activity.slotDate).toLocaleDateString(
							"nl-NL",
							{
								month: "short",
								day: "2-digit",
							},
						),
						date: activity.date,
						icon: CheckIcon,
						iconBackground: "bg-green-500",
					});
				} else {
					events.push({
						id: index + 1,
						content: `Uitgenodigd voor plek op `,
						href: `/gaten/${activity.slotId}`,
						target: new Date(activity.slotDate).toLocaleDateString(
							"nl-NL",
							{
								month: "short",
								day: "2-digit",
							},
						),
						date: activity.date,
						icon: ChatBubbleLeftRightIcon,
						iconBackground: "bg-blue-500",
					});
				}
			});
		}

		return events;
	};

	useEffect(() => {
		if (open) {
			setIsLoading(true);
			setTimeline([]);
			setActivity([]);
			setPatient({
				firstName,
				lastName,
				patientNumber,
				phoneNumber,
				priority,
			});

			const fetchPatient = async () => {
				try {
					const response = await getPatient(
						`/api/patients/${patientId}`,
					);
					setPatient(response.pt);
					setActivity(response.activity || []);
				} catch (err) {
					console.error("Error fetching patient:", err);
				} finally {
					setIsLoading(false);
				}
			};
			fetchPatient();
		}
	}, [open, patientId]);

	useEffect(() => {
		if (patient) {
			const timeline = createTimeline(activity);
			setTimeline(timeline);
		} else {
			setTimeline([]);
		}
	}, [patient, activity]);

	const handleOpen = (bool) => {
		setOpen(bool);

		if (bool === false && !onPreviewPage) {
			navigate("/patienten/all");
		}
	};

	return (
		<>
			<SidePanel open={open} setOpen={handleOpen} blank>
				{isLoading && <Loading />}
				{!isLoading && (
					<div className="flex flex-col h-full justify-between">
						<div className="overflow-auto px-10 pt-16 pb-4">
							<div className="">
								<div className="flex gap-2 items-center -mb-2">
									<h2 className="text-2xl font-bold">
										{patient.name}
										{patient.status === "deleted" && (
											<span className="italic text-md text-gray-500">
												Verwijderde patiënt
											</span>
										)}
									</h2>
									{patient.language === "en" && (
										<span className="text-3xl">🇬🇧</span>
									)}
									{patient.language === "be" && (
										<span className="text-3xl">🇧🇪</span>
									)}
									{patient.priority ? (
										<Tooltip
											id="priority-star"
											content="Markeer als geen spoed"
										>
											<SolidStar
												className="h-7 w-7 text-yellow-500 cursor-pointer"
												onClick={markAsPriority}
											/>
										</Tooltip>
									) : (
										<Tooltip
											id="priority-star"
											content="Markeer als spoed"
										>
											<OutlineStar
												className="h-7 w-7 text-gray-400 cursor-pointer"
												onClick={markAsPriority}
											/>
										</Tooltip>
									)}
								</div>
								<div>
									<div className="flex items-center gap-2 group py-2">
										<p className="text-gray-500">
											{patient.patientNumber}
										</p>
										<div className="opacity-0 group-hover:opacity-100">
											<MiniCopyButton
												text={patient.patientNumber}
												tooltip="Kopieer patiëntnummer"
											/>
										</div>
									</div>
									{patient.phoneNumber && (
										<p className="pt-4 flex">
											<PhoneIcon className="h-5 w-5 text-brand-500 mr-2" />
											<FormattedPhoneNumber
												phoneNumber={
													patient.phoneNumber
												}
											/>
										</p>
									)}
									{patient.phoneNumbers &&
										patient.phoneNumbers.map(
											(phoneNumber) => (
												<p className="pt-4 flex">
													<PhoneIcon className="h-5 w-5 text-brand-500 mr-2" />
													<FormattedPhoneNumber
														phoneNumber={
															phoneNumber
														}
													/>
												</p>
											),
										)}
								</div>

								<div></div>
								<div className="border-slate-300 border bg-slate-50 rounded-2xl px-6 py-5 mt-8 space-y-1 text-gray-500 text-sm">
									<h4 className="font-semibold text-base mb-2 text-black">
										Behandeling
									</h4>
									<p className="flex items-center pb-1 ">
										<UserIcon className="h-5 w-5 mr-2" />
										{patient.behandelaar.firstName}{" "}
										{patient.behandelaar.lastName}
									</p>
									<p className="flex items-center pb-1">
										<CalendarIcon className="h-5 w-5 mr-2" />
										{!isLoading && (
											<FormattedDate
												date={
													patient.appointment
														.datePlanned
												}
												errorMessage="Geen behandeling ingepland"
											/>
										)}
									</p>
									{patient.inviteFrom && (
										<p className="flex items-center pb-1">
											<CalendarIcon className="h-5 w-5 mr-2" />
											{!isLoading && (
												<>
													<span className="mr-1.5">
														Oproepen vanaf
													</span>
													<FormattedDate
														date={
															patient.inviteFrom
														}
													/>
												</>
											)}
										</p>
									)}
									<p className="flex items-center pb-1">
										<ClockIcon className="h-5 w-5 mr-2" />
										{!isLoading &&
											patient.appointment.duration}{" "}
										minuten
									</p>
									{patient.appointment.treatmentType && (
										<p className="flex items-center pb-1">
											<BeakerIcon className="h-5 w-5 mr-2" />
											{
												patient.appointment
													.treatmentType.name
											}
										</p>
									)}
									{patient.notes && (
										<p className="flex items-center pb-1">
											<ChatBubbleBottomCenterIcon className="h-5 w-5 mr-2" />
											"{patient.notes}"
										</p>
									)}
								</div>

								{patient.snoozeUntil &&
									new Date(patient.snoozeUntil) >
										new Date() && (
										<div className="mt-6">
											<SnoozeInfoBox
												snoozeUntil={
													patient.snoozeUntil
												}
											/>
										</div>
									)}

								{patient.availability &&
									Object.values(patient.availability).some(
										(day) =>
											Object.values(day).includes(false),
									) && (
										<div className="mt-8">
											<AvailabilityDetail
												availability={
													patient.availability
												}
											/>
										</div>
									)}
								{patient.messages.length > 2 && (
									<div className="mt-8">
										<MessageStats
											messages={patient.messages}
										/>
									</div>
								)}

								<h3 className="mt-8 font-semibold mb-4 text-base">
									Activiteit
								</h3>
								<div className="min-h-[500px] max-h-[600px] overflow-auto">
									{timeline && timeline.length > 0 ? (
										<Feed timeline={timeline} />
									) : (
										<p className="text-gray-500 text-sm">
											Geen activiteit gevonden
										</p>
									)}
								</div>
								<div
									style={{
										position: "relative",
										background:
											" rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 40%, rgba(255, 255, 255, .5) 75%, rgba(255, 255, 255, 1) 100%) repeat scroll 0 0",
										marginTop: "-100px",
										height: "100px",
									}}
								></div>
							</div>
						</div>

						{!onPreviewPage && (
							<div className="sticky bottom-0 bg-white py-4 px-6 flex space-x-2">
								<Tooltip
									id="delete-patient"
									content="Patiënt verwijderen"
									place="top-start"
								>
									<Button
										ghost
										iconPrefix={TrashIcon}
										onClick={deletePatient}
									/>
								</Tooltip>

								<Button
									fullWidth
									to={`/patienten/bewerken/${patientId}/`}
									iconPrefix={PencilSquareIcon}
								>
									Bewerken
								</Button>
							</div>
						)}
					</div>
				)}
			</SidePanel>
			<ConfirmModal />
		</>
	);
};

const MessageStats = ({ messages }) => {
	const invitations = messages.filter(
		(message) => message.type === "slot-invitation",
	);

	if (invitations.length === 0) {
		return null;
	}

	return (
		<div className="flex items-center justify-between gap-4">
			<h4 className="font-semibold text-base text-black">Statistieken</h4>
			<div className="flex items-center gap-4 text-slate-700 text-sm">
				<Tooltip
					id="invites"
					content={`${invitations.length} keer uitgenodigd`}
				>
					<div className="flex items-center gap-1">
						<SolidCalendarIcon className="w-4 h-4 text-slate-600" />{" "}
						{invitations.length}x
					</div>
				</Tooltip>
				<Tooltip
					id="responses"
					content={`Op ${Math.round((invitations.filter((m) => m.response).length / invitations.length) * 100)}% gereageerd`}
				>
					<div className="flex items-center gap-1">
						<ChatBubbleOvalLeftIcon className="w-4 h-4 text-slate-500" />{" "}
						{Math.round(
							(invitations.filter((m) => m.response).length /
								invitations.length) *
								100,
						)}
						%
					</div>
				</Tooltip>
			</div>
		</div>
	);
};

export default PatientDetail;

// return (
// 	<div className="rounded-2xl px-6 py-5 border border-slate-300 ">
// 		<h4 className="font-semibold text-base text-black mb-1">
// 			Statistieken
// 		</h4>
// 		<div className="grid grid-cols-2 gap-4 ">
// 			<div className="">
// 				<p className="text-2xl font-bold">
// 					{invitations.length}
// 					<span className="font-normal ">x</span>
// 				</p>
// 				<p className="text-sm text-gray-500">uitgenodigd</p>
// 			</div>
// 			<div className="">
// 				<p className="text-2xl font-bold">
// 					{Math.round(
// 						(invitations.filter((m) => m.response).length /
// 							invitations.length) *
// 							100,
// 					)}
// 					<span className="font-normal ">%</span>
// 				</p>
// 				<p className="text-sm text-gray-500">gereageerd</p>
// 			</div>
// 		</div>
// 	</div>
// );
