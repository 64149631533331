import React, { useEffect, useState } from "react";

import {
	ArrowPathIcon,
	CalendarIcon,
	ClockIcon,
	StopCircleIcon,
	UserIcon,
	MapPinIcon,
} from "@heroicons/react/24/outline";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useFetch from "../../shared/hooks/useFetch";
import useCustomConfirm from "../../shared/hooks/useCustomConfirm";

import Loading from "../../shared/pages/Loading";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";

import FormattedDate from "../../shared/components/util/FormattedDate";
import StatusPill from "../components/StatusPill";
import Button from "../../shared/components/FormElements/Button";
import BatchTimeline from "../components/detail/BatchTimeline";
import TasksListItem from "../../tasks/components/TasksListItem";
import AnimateIn from "../../shared/components/Animations/AnimateIn";
import SuggestedSlot from "../components/SuggestedSlot";

const SingleSlot = () => {
	const { slotId } = useParams();
	const [slot, setSlot] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isReloading, setIsReloading] = useState(false);
	const [lastUpdated, setLastUpdated] = useState();
	const [task, setTask] = useState({});

	const navigate = useNavigate();
	const fetchSlot = useFetch();
	const [confirm, ConfirmModal] = useCustomConfirm();

	const deleteSlot = async () => {
		const result = await confirm({
			title: "Weet je zeker dat je de lege plek wilt verwijderen?",
			description:
				"Patiënten die zijn uitgenodigd kunnen de afspraak niet meer bevestigen.",
			cta: "Ja, ik weet het zeker",
			cancel: "Annuleren",
			warning: true,
		});

		if (result) {
			await fetchSlot(`/api/slots/${slotId}`, { method: "DELETE" });
			navigate("/gaten");
			toast.success("Lege plek verwijderd");
		}
	};

	const reload = async () => {
		setIsReloading(true);
		const response = await fetchSlot(`/api/slots/${slotId}`);
		setSlot(response);

		if (response.status === "claimed") {
			const loadedTask = await fetchSlot(`/api/tasks/slot/${slotId}`);
			setTask(loadedTask);
		}

		setIsLoading(false);
		setIsReloading(false);
		setLastUpdated(new Date());
	};

	useEffect(() => {
		const checkReload = setInterval(() => {
			const currentTime = new Date();
			const lastUpdatedTime = new Date(lastUpdated);
			const timeDifference =
				(currentTime - lastUpdatedTime) / (1000 * 60); // Calculate time difference in minutes

			if (timeDifference >= 1 && document.visibilityState === "visible") {
				reload();
			} else if (timeDifference >= 10) {
				reload();
			}
		}, 60000);

		return () => clearInterval(checkReload); // Clear interval on component unmount
	}, [lastUpdated]);

	useEffect(() => {
		if (slotId) {
			const fetchSingleSlot = async () => {
				await reload(); // Use reload function to fetch slot data
			};

			fetchSingleSlot();
		}
	}, [fetchSlot, slotId]);

	return (
		<>
			<>
				{isLoading && <Loading />}
				{!isLoading && (
					<>
						<div className="border-brand-500 border-b-6">
							<div className="flex pt-20 pb-16 bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 lg:pr-20">
								<div className="w-4/5">
									<div className="flex items-center">
										<Heading>
											<FormattedDate date={slot.date} />
										</Heading>
									</div>
									<div className="pt-6">
										<StatusPill
											status={slot.status}
											size="medium"
											long
										/>
									</div>
								</div>
								{slot.status !== "claimed" && (
									<div className="mt-10 w-1/5">
										<Button
											ghost
											iconPrefix={StopCircleIcon}
											fullWidth
											onClick={deleteSlot}
										>
											Stop uitnodigen
										</Button>
									</div>
								)}
							</div>
						</div>
						<Container>
							{slot.status === "claimed" && !isReloading && (
								<AnimateIn>
									<div className="mb-5">
										<div className="p-6 bg-white border-gray border rounded-2xl shadow-sm">
											<h3 className="font-semibold text-md pb-2">
												Gelukt, de lege plek is gevuld
												🎉 - Verplaats de afspraak in de
												agenda
											</h3>
											<div className="-mb-6">
												<TasksListItem
													task={task}
													setTasks={() => {
														setTask({
															...task,
															isChecked:
																!task.isChecked,
														});
													}}
													isChecked={task.isChecked}
												/>
											</div>
										</div>
									</div>
								</AnimateIn>
							)}

							{slot.status === "claimed" && (
								<AnimateIn>
									<div className="mb-10">
										<SuggestedSlot slotId={slotId} />
									</div>
								</AnimateIn>
							)}

							<div className="flex">
								<div className="w-1/4">
									<h3 className="font-semibold text-md mb-2">
										Details
									</h3>
									<p className="text-gray-500 flex items-center pb-2 text-sm">
										<UserIcon className="h-5 w-5 text-gray-500 mr-3" />
										<p className="text-gray-600">
											{slot.behandelaar?.firstName}{" "}
											{slot.behandelaar?.lastName}
										</p>
									</p>
									<p className="text-gray-600 flex items-center pb-1.5 text-sm">
										<CalendarIcon className="h-5 w-5 text-gray-500 mr-3" />
										<FormattedDate date={slot.date} />
									</p>
									<p className="text-gray-500 flex items-center pb-1.5 text-sm">
										<ClockIcon className="h-5 w-5 text-gray-500 mr-3" />
										<p className="text-gray-600">
											{slot.startTime} - {slot.endTime}{" "}
											uur
										</p>
									</p>
									{slot.location && (
										<p className="text-gray-500 flex items-center pb-1.5 text-sm">
											<MapPinIcon className="h-5 w-5 text-gray-500 mr-3" />
											{slot.location.name}
										</p>
									)}
								</div>
								<div className="w-3/4 pl-6">
									<div className="flex mb-2">
										<h3 className="font-semibold text-md w-1/2">
											Activiteit
										</h3>

										{new Date(slot.dateCreated) >
											new Date("2024-04-11T16:30:00") && (
											<div className="w-1/2 flex justify-end items-center">
												<p className="text-xs text-gray-500">
													{isReloading ? (
														"Aan het bijwerken..."
													) : (
														<FormattedDate
															relative
															date={lastUpdated}
															verb="bijgewerkt"
														/>
													)}
												</p>

												<button
													onClick={reload}
													disabled={isLoading}
												>
													<ArrowPathIcon className="h-4 w-4 text-gray-500 ml-2 hover:scale-105 hover:text-gray-800" />
												</button>
											</div>
										)}
									</div>
									<AnimateIn
										type="fromTop"
										duration={500}
										delay={50}
									>
										<div className="bg-white px-8 py-10 border-slate-200 border rounded-2xl">
											{new Date(slot.dateCreated) >
											new Date("2024-04-11T16:30:00") ? (
												<BatchTimeline
													batches={slot.batches}
													sent={true}
													claimedBy={slot.claimedBy}
													slotId={slot._id}
													slotDuration={slot.duration}
													reload={reload}
													hideCreatiefVullenNotice={
														slot.extraPatientsInvited ||
														slot.status ===
															"claimed"
													}
													hideNewPatientsNotice={
														slot.status ===
														"claimed"
													}
													slotDate={slot.dateCreated}
												/>
											) : (
												<p className="text-gray-500 text-sm">
													Je ziet het overzicht voor
													lege plekken die later dan
													11 april zijn aangemaakt
												</p>
											)}
										</div>
									</AnimateIn>
								</div>
							</div>
						</Container>
					</>
				)}
			</>
			<ConfirmModal />
		</>
	);
};

export default SingleSlot;
