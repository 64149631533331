import React, { useState, useEffect } from "react";
import useFetch from "../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import { XCircleIcon } from "@heroicons/react/16/solid";

const PatientsFilters = ({ patients = [], onFilterChange }) => {
	const fetchBehandelaars = useFetch();
	const location = useLocation();
	const { user } = useUser();

	const query = new URLSearchParams(location.search);

	const [behandelaars, setBehandelaars] = useState([]);
	const [searchItem, setSearchItem] = useState("");
	const [behandelaarFilter, setBehandelaarFilter] = useState(
		query.get("behandelaar") || "all",
	);

	useEffect(() => {
		async function getBehandelaars() {
			const response = await fetchBehandelaars(
				`/api/behandelaars/${user.id}?onlyActive=true`,
			);
			setBehandelaars(response);
		}
		getBehandelaars();
	}, [user]);

	useEffect(() => {
		if (behandelaarFilter !== "all") {
			const filteredPatients = patients.filter(
				(patient) => patient.behandelaar._id === behandelaarFilter,
			);
			onFilterChange(filteredPatients);
		} else {
			onFilterChange(patients);
		}
	}, [behandelaarFilter, patients]);

	const handleSearchChange = (e) => {
		const searchTerm = e.target.value.trim().toLowerCase();
		setSearchItem(searchTerm);

		const filteredItems = patients.filter((patient) => {
			const firstNameMatch = patient.firstName
				? patient.firstName.toLowerCase().includes(searchTerm)
				: false;
			const lastNameMatch = patient.lastName
				? patient.lastName.toLowerCase().includes(searchTerm)
				: false;
			const patientNumberMatch = patient.patientNumber
				? patient.patientNumber.toLowerCase().includes(searchTerm)
				: false;
			const fullNameMatch = patient.fullName
				? patient.fullName.toLowerCase().includes(searchTerm)
				: false;

			const phoneNumbersMatch = patient.phoneNumbers
				? patient.phoneNumbers.some((phone) =>
						phone
							.replace(/\s/g, "")
							.replace("+31", "0")
							.toLowerCase()
							.includes(
								searchTerm
									.replace(/\s/g, "")
									.replace("+31", "0"),
							),
					)
				: false;

			const phoneNumberMatch = patient.phoneNumber
				? patient.phoneNumber
						.replace(/\s/g, "")
						.replace("+31", "0")
						.toLowerCase()
						.includes(
							searchTerm.replace(/\s/g, "").replace("+31", "0"),
						)
				: false;

			return (
				firstNameMatch ||
				lastNameMatch ||
				patientNumberMatch ||
				phoneNumbersMatch ||
				phoneNumberMatch ||
				fullNameMatch
			);
		});

		onFilterChange(filteredItems);
	};

	const clearFilters = () => {
		setSearchItem("");
		setBehandelaarFilter("all");
		onFilterChange(patients);
	};

	const handleBehandelaarChange = (e) => {
		setBehandelaarFilter(e.target.value);

		const filteredPatients = patients.filter(
			(patient) => patient.behandelaar._id === e.target.value,
		);
		onFilterChange(filteredPatients);
	};

	if (!patients || !behandelaars) return null;

	return (
		<>
			<div className="flex space-x-4 items-center">
				<div>
					<select
						value={behandelaarFilter}
						onChange={handleBehandelaarChange}
						className="focus:ring-0 w-fit py-1.5 pr-8 rounded-lg flex text-sm text-slate-800 px-3 items-center focus:ring-brand-400 border-1 border-slate-400 border-dashed bg-transparent"
					>
						<option value="all" disabled>
							Filter op behandelaar
						</option>
						{behandelaars.map((behandelaar) => (
							<option
								key={behandelaar._id}
								value={behandelaar._id}
							>
								{behandelaar.firstName}
								{behandelaar.lastName &&
									` ${behandelaar.lastName}`}
							</option>
						))}
					</select>
				</div>

				<div className="w-fit rounded-lg flex bg-slate-200 px-3 items-center focus:ring-2 focus:ring-brand-400">
					<MagnifyingGlassIcon className="w-4 h-4 text-slate-600 mr-1.5" />
					<input
						type="text"
						value={searchItem}
						onChange={handleSearchChange}
						placeholder="Zoek een patiënt..."
						className="block border-0 py-1.5 text-slate-800 bg-slate-200 ring-0 placeholder:text-slate-500 ring-inset px-0 sm:text-sm sm:leading-6"
					/>
				</div>
				{(searchItem ||
					(behandelaarFilter && behandelaarFilter !== "all")) && (
					<button
						onClick={clearFilters}
						className="text-sm text-brand-500 hover:text-brand-600 font-semibold pl-2 flex items-center"
					>
						<XCircleIcon className="size-3 mr-1" /> Filters
					</button>
				)}
			</div>
		</>
	);
};

export default PatientsFilters;
