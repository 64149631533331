import React from "react";

import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import BehandelaarAvatar from "../../practice/components/Behandelaren/BehandelaarAvatar";
import FormattedDate from "../../shared/components/util/FormattedDate";
import StatusPill from "./StatusPill";
import Tooltip from "../../shared/components/UIElements/Tooltip";

import blueTicks from "../../assets/BlueTicks.png";

const SlotsTableItem = ({
	slotID,
	behandelaarFirstName,
	behandelaarLastName,
	date,
	startTime,
	endTime,
	status,
	batches,
	itemClickable = true,
	archive = false,
	claimedBy,
}) => {
	const navigate = useNavigate();

	const openSlot = () => {
		if (itemClickable) {
			navigate(`/gaten/${slotID}`);
		}
	};

	const calculateResponseRate = () => {
		const invitedPatients = batches.flatMap((batch) => {
			if (new Date(batch.dateSent) < new Date()) {
				return batch.patients;
			}
			return [];
		});

		const totalInvited = invitedPatients.length;

		const readCount = invitedPatients.filter((pt) =>
			pt.patient.messages?.some((message) => {
				return (
					message.slotId === slotID &&
					(message.deliveryStatus === "read" ||
						message.response === "accepted" ||
						message.response === "rejected")
				);
			}),
		).length;
		return { readCount, totalInvited };
	};

	return (
		<>
			<tr
				onClick={openSlot}
				className={classNames(
					itemClickable
						? "hover:bg-gray-50 hover:transition-all hover:duration-75 cursor-pointer"
						: "cursor-default",
				)}
				data-record="true"
			>
				<td className="px-6 py-4 whitespace-nowrap">
					<div className="text-sm font-medium text-gray-900">
						<FormattedDate date={date} />
					</div>
					<div className="text-sm text-gray-500">
						{startTime} - {endTime} uur
					</div>
				</td>
				<td className="px-6 py-4 whitespace-nowrap">
					<div className="flex items-center">
						<div className="flex-shrink-0 h-10 w-10">
							<BehandelaarAvatar
								firstName={behandelaarFirstName}
								lastName={behandelaarLastName}
							/>
						</div>
						<div className="ml-4">
							<div className="text-sm  text-gray-900">
								{behandelaarFirstName} {behandelaarLastName}
							</div>
						</div>
					</div>
				</td>
				<td className="px-6 py-4 whitespace-nowrap">
					{archive && claimedBy ? (
						<div className="cursor-help">
							<Tooltip
								content={`Door patiënt #${claimedBy}`}
								placement="top"
								id={`archive_tooltip_${slotID}`}
							>
								<StatusPill status={status} size="medium" />
							</Tooltip>
						</div>
					) : (
						<StatusPill status={status} size="medium" />
					)}
				</td>
				{!archive && (
					<td className="px-6 py-4 whitespace-nowrap">
						<Tooltip
							content={`${calculateResponseRate().readCount}/${calculateResponseRate().totalInvited} gelezen`}
							id={`read-rate_row_${slotID}`}
						>
							<p className="text-sm font-medium flex gap-x-1 items-center">
								<img
									src={blueTicks}
									alt="Read"
									className="h-[11px] w-auto"
								/>
								{calculateResponseRate().readCount}/
								{calculateResponseRate().totalInvited}
							</p>
						</Tooltip>
					</td>
				)}
			</tr>
		</>
	);
};

export default SlotsTableItem;
