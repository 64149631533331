import SkeletonLoadingItem from "./SkeletonLoadingItem";
import NumberFlow from "@number-flow/react";

const CardWithIcon = ({
	icon: Icon,
	value,
	label,
	subtitle,
	isLoading,
	hideWhenZero = true,
	children,
	preventAnimation = false,
}) => {
	// if (
	// 	hideWhenZero &&
	// 	(value === "0" ||
	// 		value === 0 ||
	// 		value === "00:00" ||
	// 		value === "0 / 0 (0.00%)")
	// ) {
	// 	return null;
	// }

	return (
		<div className="bg-white p-6 rounded-3xl border-slate-200 border shadow-sm flex items-start gap-x-2 justify-between">
			<div>
				<p className="font-bold text-xl flex items-center gap-x-1.5">
					<>
						{!preventAnimation ? (
							<NumberFlow
								value={isLoading ? 0 : value}
								trend={false}
							/>
						) : (
							<span data-record="true">{value}</span>
						)}
					</>

					{label && (
						<span className="font-normal" data-record="true">
							{label}
						</span>
					)}
				</p>

				{children}
				{subtitle && (
					<p className="text-slate-500" data-record="true">
						{subtitle}
					</p>
				)}
			</div>
			<div className="self-end">
				<Icon className="size-6 text-brand-intense" />
			</div>
		</div>
	);
};

export default CardWithIcon;
