import React from "react";

import useFetch from "../../shared/hooks/useFetch";
import { useParams } from "react-router-dom";

import {
	CalendarDaysIcon,
	CalendarIcon,
	MoonIcon,
	TrashIcon,
} from "@heroicons/react/24/outline";

import Container from "../../shared/components/UIElements/Container";
import Button from "../../shared/components/FormElements/Button";
import Heading from "../../shared/components/UIElements/Heading";
import UpgradeToYearly from "../../billing/components/UpgradeToYearly";

const ManageMySubscription = () => {
	const { patientId } = useParams();
	const key = new URLSearchParams(window.location.search).get("key");

	return (
		<Container transparent>
			<div className="text-center pb-10 pt-10">
				<Heading>Wat wilt u doen?</Heading>
			</div>
			<div className="text-center flex flex-col items-center">
				<p className="text-gray-800 text-sm pb-4">
					Krijg gerichter uitnodigingen door aan te geven wanneer u
					zeker niet kunt.
				</p>
				<Button
					iconPrefix={CalendarIcon}
					to={`/c/beschikbaarheid/${patientId}?key=${key}`}
				>
					Geef beschikbaarheid op
				</Button>
			</div>

			<div className="relative mt-10 pt-10">
				<p className="absolute -top-3 left-1/2 transform -translate-x-1/2 z-20 bg-white px-2 text-center text-gray-500 text-sm rounded-full">
					of
				</p>
				<div className="border-t border-gray-200 -mt-10"></div>
			</div>
			<div className="text-center flex flex-col items-center">
				<p className="text-gray-800 text-sm pb-4">
					Bent u met vakantie? Krijg een tijdje geen uitnodigingen
					meer voor vrijgekomen plekken.
				</p>
				<Button
					iconPrefix={MoonIcon}
					to={`/c/snooze/${patientId}?key=${key}`}
				>
					Snooze uitnodigingen
				</Button>
			</div>

			<div className="relative mt-10 pt-10">
				<p className="absolute -top-3 left-1/2 transform -translate-x-1/2 z-20 bg-white px-2 text-center text-gray-500 text-sm rounded-full">
					of
				</p>
				<div className="border-t border-gray-200 -mt-10"></div>
			</div>
			<div className="text-center flex flex-col items-center">
				<Button
					ghost
					iconPrefix={TrashIcon}
					to={`/c/verwijder/${patientId}?key=${key}`}
				>
					Haal me van de Snelterecht lijst af
				</Button>
				<p className="text-gray-500 text-xs mt-4">
					Uw gegevens worden geanonimiseerd in onze systemen en u
					wordt niet meer opgeroepen voor plekken in de agenda die
					vrijkomen
				</p>
			</div>
		</Container>
	);
};

export default ManageMySubscription;
