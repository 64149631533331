import React, { useEffect, useState } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import Table from "../../../shared/components/Tables/Table";
import { Link } from "react-router-dom";
import Heading from "../../../shared/components/UIElements/Heading";
import SubHeading from "../../../shared/components/UIElements/SubHeading";
import {
	BuildingOfficeIcon,
	CurrencyEuroIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";

const ChurnedPractices = () => {
	const fetchData = useFetch();
	const { user } = useUser();
	const [churnedPractices, setChurnedPractices] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchStats = async () => {
			setIsLoading(true);
			const data = await fetchData(
				`/api/admin/practices/churned/${user.id}`,
			);
			setChurnedPractices(data);
			setIsLoading(false);
		};
		fetchStats();
	}, [fetchData, user.id]);

	return (
		<>
			<Heading>Churned Practices</Heading>
			<div className="mt-10">
				<SubHeading icon={BuildingOfficeIcon}>
					All Churned Practices ({churnedPractices.length})
				</SubHeading>
				<Table data={churnedPractices} isLoading={isLoading}>
					<th
						scope="col"
						className="pr-3 py-3.5 pl-6 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<>
								<div
									className={classNames(
										"group flex items-center",
									)}
								>
									<p className="font-bold text-black">
										<Link
											to={`/admin/practice/?id=${row.practice._id}`}
											className="hover:underline"
										>
											{row.practice.name}
										</Link>
									</p>
								</div>
								<p className="text-gray-500">
									{row.practice.creator.firstName}{" "}
									{row.practice.creator.lastName}
								</p>
							</>
						)}
					>
						Praktijk
					</th>

					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							row.allTimeStats.slotsCreated === 0 ? (
								"—"
							) : (
								<div>
									<span
										className={classNames({
											"text-green-500 font-semibold":
												row.allTimeStats.slotsClaimed >
												3,
										})}
									>
										{row.allTimeStats.slotsClaimed}
									</span>
									/{row.allTimeStats.slotsCreated}{" "}
									<span className="text-gray-500">
										(
										{row.allTimeStats.fillRate.toFixed(2) *
											100}
										%)
									</span>
								</div>
							)
						}
					>
						Gevulde plekken
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							row.allTimeStats.patientsAdded === 0
								? "—"
								: row.allTimeStats.patientsAdded
						}
					>
						Patiënten toegevoegd
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							row.allTimeStats.callsSaved === 0
								? "—"
								: row.allTimeStats.callsSaved
						}
					>
						Uitnodigingen verstuurd
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							row.financeStats ? (
								<>
									<CurrencyEuroIcon className="inline-block w-4 h-4 mr-1" />
									{row.financeStats.allTimeSpend}
								</>
							) : (
								"N/A"
							)
						}
					>
						Totaal uitgegeven
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							row.financeStats ? (
								<>
									{new Date(
										row.financeStats.activeSince,
									).toLocaleDateString()}
									<br />
									<span className="text-gray-500">
										({row.financeStats.activeFor} months)
									</span>
								</>
							) : (
								"N/A"
							)
						}
					>
						Actief sinds
					</th>
				</Table>
			</div>
		</>
	);
};

export default ChurnedPractices;
