import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import useFetch from "../../shared/hooks/useFetch";

import NewPatientPage from "./NewPatientPage";

const EditPatientPage = ({ open, setOpen }) => {
	const [patient, setPatient] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const { patientId } = useParams();

	const fetchPatient = useFetch();

	useEffect(() => {
		const getPatient = async () => {
			try {
				const res = await fetchPatient(`/api/patients/${patientId}`);

				const response = res.pt;

				if (!response) {
					throw new Error("No patient data received");
				}

				setPatient({
					id: response._id,
					firstName: response.firstName,
					lastName: response.lastName,
					phoneNumber:
						response.phoneNumber ||
						(response.phoneNumbers && response.phoneNumbers[0]),
					phoneNumber2:
						response.phoneNumbers && response.phoneNumbers[1],
					phoneNumber3:
						response.phoneNumbers && response.phoneNumbers[2],
					unformattedDate: response.appointment?.datePlanned
						? dayjs(response.appointment.datePlanned)
						: null,
					otherBehandelaars: response.appointment?.behandelaarType
						? true
						: false,
					treatment:
						response.appointment?.treatmentType &&
						response.appointment.treatmentType._id,
					notes: response.notes,
					priority: response.priority,
					unformattedInviteFrom: response.inviteFrom
						? dayjs(response.inviteFrom)
						: null,
					behandelaar: response.behandelaar?._id,
					patientnummer: response.patientNumber,
					behandelingDuur: response.appointment?.duration
						? +response.appointment.duration
						: null,
					allowedLocations: response.allowedLocations,
					language: response.language,
				});
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching patient:", error);
				setIsLoading(false);
			}
		};

		getPatient();
	}, [patientId, fetchPatient]);

	if (!isLoading) {
		return (
			<>
				<NewPatientPage isEditing={true} initialPatient={patient} />
			</>
		);
	}

	return null;
};

export default EditPatientPage;
