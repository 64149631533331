import React from "react";
import { useUser } from "@clerk/clerk-react";
import { Formik, Form } from "formik";
import LanguagePicker from "../../../patients/components/creation-wizard/LanguagePicker";
import Heading from "../../../shared/components/UIElements/Heading";
import Button from "../../../shared/components/FormElements/Button";

const LanguagePreferences = () => {
	const { user } = useUser();

	const handleSubmit = async (values) => {
		try {
			await user.update({
				unsafeMetadata: {
					...user.unsafeMetadata,
					preferences: {
						...user.unsafeMetadata?.preferences,
						language: values.language,
					},
				},
			});
		} catch (error) {
			console.error("Error updating language preference:", error);
		}
	};

	const initialValues = {
		language: user.unsafeMetadata?.preferences?.language || "nl",
	};

	return (
		<div>
			<Heading size="3">Standaard taal</Heading>
			<p className="text-sm text-slate-700 pt-2">
				Deze taal wordt standaard geselecteerd bij het toevoegen van
				nieuwe patiënten
			</p>
			<Formik initialValues={initialValues} onSubmit={handleSubmit}>
				{({ values, setFieldValue }) => (
					<Form>
						<div className="max-w-xl mb-4">
							<LanguagePicker
								name="language"
								// label="Standaard taal voor patiënten"
								value={values.language}
								onChange={(value) =>
									setFieldValue("language", value)
								}
							/>
							<div className="mt-4">
								<Button type="submit" size="medium">
									Opslaan
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default LanguagePreferences;
