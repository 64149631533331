import { InformationCircleIcon } from "@heroicons/react/16/solid";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import classNames from "classnames";

const AvailabilityDetail = ({ availability, forPatient = false }) => {
	const days = [
		{ key: "monday", longLabel: "Maandag", shortLabel: "Ma" },
		{ key: "tuesday", longLabel: "Dinsdag", shortLabel: "Di" },
		{ key: "wednesday", longLabel: "Woensdag", shortLabel: "Wo" },
		{ key: "thursday", longLabel: "Donderdag", shortLabel: "Do" },
		{ key: "friday", longLabel: "Vrijdag", shortLabel: "Vr" },
		{ key: "saturday", longLabel: "Zaterdag", shortLabel: "Za" },
	];

	const formatAvailability = () => {
		const liClassNames =
			"bg-slate-50 border border-slate-300 rounded-full px-3 py-1.5 text-gray-600 text-sm w-fit";
		return days
			.map((day) => {
				const { morning, afternoon, evening } = availability[day.key];
				const unavailableTimes = [];
				if (!morning) unavailableTimes.push("ochtend");
				if (!afternoon) unavailableTimes.push("middag");
				if (!evening) unavailableTimes.push("avond");

				if (unavailableTimes.length === 3) {
					return (
						<div key={day.key} className={liClassNames}>
							{day.longLabel}
						</div>
					);
				} else if (unavailableTimes.length > 0) {
					return (
						<div key={day.key} className={liClassNames}>
							{day.shortLabel}: {unavailableTimes.join(" en ")}
						</div>
					);
				}
				return null;
			})
			.filter((item) => item !== null);
	};

	return (
		<div>
			<div className="flex gap-2 mb-3 items-center">
				<h4
					className={classNames("font-semibold", {
						"text-xl": forPatient,
						"text-base": !forPatient,
					})}
				>
					{!forPatient
						? "Is niet beschikbaar op..."
						: "We sturen u geen uitnodigingen meer voor afspraken op de..."}
				</h4>
				{!forPatient && (
					<Tooltip
						content="Dit heeft de patiënt zelf aangegeven via WhatsApp"
						id="availability"
						place="top-start"
					>
						<InformationCircleIcon className="size-4 text-slate-400 cursor-help" />
					</Tooltip>
				)}
			</div>
			<div className="flex flex-row gap-2 flex-wrap">
				{formatAvailability()}
			</div>
		</div>
	);
};

export default AvailabilityDetail;
