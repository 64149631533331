import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Button from "../../shared/components/FormElements/Button";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import Loading from "../../shared/pages/Loading";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";
import useFetch from "../../shared/hooks/useFetch";
import FormattedDate from "../../shared/components/util/FormattedDate";
import UpdatedSuccessfullyPage from "../components/UpdatedSuccessfully";
import { PencilSquareIcon } from "@heroicons/react/16/solid";
import SnoozeInfoBox from "../../patients/components/SnoozeInfoBox";

const SnoozeInvitations = () => {
	const [patient, setPatient] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isDeleted, setIsDeleted] = useState(false);
	const [number, setNumber] = useState(0);
	const [snoozeUntil, setSnoozeUntil] = useState(null);
	const [isUpdated, setIsUpdated] = useState(false);
	const [snoozeUntilDate, setSnoozeUntilDate] = useState(null);

	const { patientId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	// Get a specific query parameter
	const key = searchParams.get("key");

	// Function to send data to API - implement as needed
	const snoozePatient = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/public/patient/${patientId}/snooze?key=${key}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ snoozeForDays: number }),
			},
		);

		if (response.ok) {
			setIsUpdated(true);
		}
	};

	useEffect(() => {
		const fetchPatient = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/public/patient/${patientId}`,
			);
			const responseData = await response.json();
			setPatient(responseData);
			setIsLoading(false);

			if (responseData.status === "deleted") {
				setIsDeleted(true);
			} else {
				if (responseData.snoozeUntil) {
					const snoozeDate = new Date(responseData.snoozeUntil);
					const options = {
						weekday: "long",
						month: "long",
						day: "numeric",
					};
					const formattedDate = snoozeDate.toLocaleDateString(
						"nl-NL",
						options,
					);
					setSnoozeUntil(formattedDate);
					const daysDifference = Math.ceil(
						(snoozeDate - new Date()) / (1000 * 60 * 60 * 24),
					);
					setNumber(daysDifference);
				} else {
					setSnoozeUntil(null);
				}
			}
		};

		if (!isDeleted) {
			fetchPatient();
		}
	}, [isDeleted]);

	useEffect(() => {
		const snoozeDate = new Date(
			new Date().getTime() + number * 24 * 60 * 60 * 1000,
		);
		setSnoozeUntilDate(snoozeDate);
		const options = {
			weekday: "long",
			month: "long",
			day: "numeric",
		};
		const formattedDate = snoozeDate.toLocaleDateString("nl-NL", options);
		setSnoozeUntil(formattedDate);
	}, [number]);

	if (isLoading || (!patient && !isDeleted)) return <Loading />;

	const handleDecrease = () => {
		setNumber((prev) => Math.max(prev - 1, 0));
	};

	const handleIncrease = () => {
		setNumber((prev) => prev + 1);
	};

	if (isUpdated) {
		return (
			<UpdatedSuccessfullyPage>
				<div className="mt-6">
					<MiniBackButton to={`/c/pt/${patientId}/?key=${key}`} />
					<h1 className="text-xl font-semibold">
						We sturen u pas weer uitnodigingen na uw vakantie
					</h1>
				</div>
				<div className="mt-6">
					<SnoozeInfoBox
						snoozeUntil={snoozeUntilDate.toISOString()}
						forPatient
					/>
				</div>
				<Link
					className="mt-4 flex items-center font-semibold text-brand-intense"
					to={`/c/pt/${patientId}/?key=${key}`}
				>
					<PencilSquareIcon className="size-4 mr-2" />
					Aanpassen
				</Link>
			</UpdatedSuccessfullyPage>
		);
	}

	return (
		<>
			<div className="flex flex-col h-[100dvh] justify-between px-6 pt-24 pb-6">
				<div>
					<MiniBackButton to={`/c/pt/${patientId}?key=${key}`} />
					<h1 className="text-3xl font-bold">
						Bent u met vakantie? Snooze uitnodigingen van de
						praktijk.
					</h1>
					<p className="text-gray-600 pt-3">
						U krijgt dan zolang geen uitnodigingen voor vrijgekomen
						plekken in de agenda.
					</p>
				</div>
				<div>
					<p className="text-gray-600 mb-2 text-center">
						Stuur me zoveel dagen geen uitnodigingen
					</p>
					<div className="flex items-center space-x-2">
						<Button
							onClick={handleDecrease}
							iconPrefix={MinusIcon}
						/>
						<input
							type="number"
							value={number}
							onChange={(e) => setNumber(Number(e.target.value))}
							min={0}
							max={50}
							className="w-full text-center border border-gray-300 rounded-xl py-3 text-xl font-semibold"
						/>
						<Button
							onClick={handleIncrease}
							iconPrefix={PlusIcon}
						/>
					</div>
					<p className="text-center text-gray-600 mt-4">
						We sturen je pas weer uitnodigingen vanaf{" "}
						<span className="font-bold">{snoozeUntil}</span>
					</p>
				</div>

				<div>
					<Button
						onClick={snoozePatient}
						fullWidth
						classNames="bg-red-600 hover:bg-red-700"
					>
						Snooze uitnodigingen
					</Button>
				</div>
			</div>
		</>
	);
};

export default SnoozeInvitations;
