import React, { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import useFetch from "../../hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import CheckboxGroup from "./CheckboxGroup";

const LocationsChecklist = ({ name, label }) => {
	const [locations, setLocations] = useState([]);
	const { user } = useUser();
	const fetchLocations = useFetch();
	const [field] = useField(name);
	const { setFieldValue } = useFormikContext();

	useEffect(() => {
		const getLocations = async () => {
			try {
				const fetchedLocations = await fetchLocations(
					`/api/practice/user/${user.id}/locations`,
					"GET",
				);
				setLocations(fetchedLocations);

				// Set all locations as checked by default
				if (fetchedLocations.length > 0 && !field.value?.length) {
					setFieldValue(
						name,
						fetchedLocations.map((loc) => loc._id),
					);
				}
			} catch (error) {
				console.error("Error fetching locations:", error);
			}
		};

		if (user) {
			getLocations();
		}
	}, [user, fetchLocations, setFieldValue, name, field.value]);

	if (locations.length < 2) {
		return null;
	}

	const locationOptions = locations.map((location) => ({
		label: location.name,
		value: location._id,
	}));

	return (
		<CheckboxGroup
			name={name}
			label={label}
			options={locationOptions}
			autoCheck={true}
		/>
	);
};

export default LocationsChecklist;
