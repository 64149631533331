import React from "react";
import CutoffDaysInviteSettings from "../../../practice/components/CutoffDaysInviteSettings";
import Heading from "../../../shared/components/UIElements/Heading";
import LanguagePreferences from "./LanguagePreferences";

const InvitePreferences = () => {
	return (
		<>
			<Heading size="2">Voorkeuren</Heading>
			<div className="mt-8">
				<CutoffDaysInviteSettings />
			</div>
			<div className="mt-16 border-t border-gray-200 pt-8">
				<LanguagePreferences />
			</div>
		</>
	);
};

export default InvitePreferences;
