import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../../shared/hooks/useFetch";
import Heading from "../../../shared/components/UIElements/Heading";
import {
	ChatBubbleOvalLeftIcon,
	CheckCircleIcon,
	ClockIcon,
} from "@heroicons/react/20/solid";
import {
	DateRangePicker,
	Tab,
	TabGroup,
	TabList,
	TabPanel,
	TabPanels,
} from "@tremor/react";
import CardWithIcon from "../../../shared/components/UIElements/CardWithIcon";
import { CircleWithMetric } from "../../../shared/components/Charts/CircleWithMetric";
import DonutChart from "../../../shared/components/Charts/DonutChart";

const MessageStatistics = () => {
	const { user } = useUser();
	const fetchData = useFetch();
	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
	});
	const [messageStats, setMessageStats] = useState({
		deliveryStats: {},
		responseStats: {},
		channelStats: {},
		totalMessages: 0,
		readRate: 0,
		averageResponseTimeMinutes: 0,
		averageTimeToReadMinutes: 0,
		responseRate: 0,
		claimedPatientStats: {
			totalClaimedPatients: 0,
			invitationRanges: {},
		},
		messageHistory: {
			totalPatients: 0,
			totalMessages: 0,
			averageMessagesPerPatient: 0,
			distribution: {},
		},
	});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchMessageStats = async () => {
			try {
				setIsLoading(true);
				const data = await fetchData(
					`/api/admin/messages/stats?adminId=${user.id}&from=${dateRange.from.toISOString()}&to=${dateRange.to.toISOString()}`,
				);
				setMessageStats(data);
			} catch (error) {
				console.error("Error fetching message stats:", error);
			}
			setIsLoading(false);
		};

		fetchMessageStats();
	}, [fetchData, user?.id, dateRange]);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	const deliveryData = Object.entries(messageStats.deliveryStats || {}).map(
		([name, value]) => ({
			name,
			value,
		}),
	);

	const responseData = Object.entries(messageStats.responseStats || {}).map(
		([name, value]) => ({
			name,
			value,
		}),
	);

	const channelData = Object.entries(messageStats.channelStats || {}).map(
		([name, value]) => ({
			name,
			value,
		}),
	);

	return (
		<div>
			<div className="flex items-center justify-between mb-10">
				<Heading>Berichten statistieken</Heading>
				<DateRangePicker
					value={dateRange}
					onValueChange={setDateRange}
				/>
			</div>

			<div className="grid grid-cols-3 gap-4 mb-10">
				<CardWithIcon icon={ChatBubbleOvalLeftIcon}>
					<CircleWithMetric
						value={messageStats.readRate}
						metric={`${messageStats.readRate.toFixed(1)}% read rate`}
						label={`${messageStats.deliveryStats.read || 0} van ${messageStats.totalMessages} berichten gelezen`}
					/>
				</CardWithIcon>

				<CardWithIcon icon={CheckCircleIcon}>
					<CircleWithMetric
						value={messageStats.responseRate}
						metric={`${messageStats.responseRate.toFixed(1)}% response rate`}
						label={`${messageStats.responseStats.accepted || 0} geaccepteerd`}
					/>
				</CardWithIcon>

				<CardWithIcon icon={ClockIcon}>
					<CircleWithMetric
						value={messageStats.averageTimeToReadMinutes}
						metric={`${messageStats.averageTimeToReadMinutes} minuten`}
						label="gemiddelde leestijd"
					/>
				</CardWithIcon>
			</div>

			<div className="bg-white rounded-lg p-6 shadow mb-10">
				<h3 className="text-lg font-medium mb-4">
					Berichten voor claim
				</h3>
				<p className="mb-2">
					Totaal aantal geclaimde patiënten:{" "}
					{messageStats.claimedPatientStats.totalClaimedPatients}
				</p>
				{Object.entries(
					messageStats.claimedPatientStats.invitationRanges || {},
				).map(([range, { count, percentage }]) => (
					<p key={range} className="text-gray-600">
						{range} berichten: {count} patiënten ({percentage}%)
					</p>
				))}
			</div>

			{messageStats.messageHistory && (
				<div className="bg-white rounded-lg p-6 shadow mb-10">
					<h3 className="text-lg font-medium mb-4">
						Message History Analysis
					</h3>
					<p>
						Total Claimed Patients:{" "}
						{messageStats.messageHistory.totalPatients}
					</p>
					<p>
						Average Messages Before Claim:{" "}
						{messageStats.messageHistory.averageMessagesPerPatient}
					</p>
					<div className="mt-4">
						<h4 className="font-medium mb-2">Distribution:</h4>
						{Object.entries(
							messageStats.messageHistory.distribution || {},
						).map(([count, patients]) => (
							<p key={count} className="text-gray-600">
								{count} messages: {patients} patients (
								{(
									(patients /
										messageStats.messageHistory
											.totalPatients) *
									100
								).toFixed(1)}
								%)
							</p>
						))}
					</div>
				</div>
			)}

			<TabGroup>
				<TabList>
					<Tab>Aflevering</Tab>
					<Tab>Reacties</Tab>
					<Tab>Kanalen</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<div className="mt-4">
							<DonutChart
								data={deliveryData}
								category="value"
								index="name"
								variant="pie"
								valueFormatter={(value) =>
									`${value}x (${Math.round(
										(value / messageStats.totalMessages) *
											100,
									)}%)`
								}
								colors={["green-500", "blue-600", "red-600"]}
								includeLegend={true}
							/>
						</div>
					</TabPanel>
					<TabPanel>
						<div className="mt-4">
							<DonutChart
								data={responseData}
								category="value"
								index="name"
								variant="pie"
								valueFormatter={(value) =>
									`${value}x (${Math.round(
										(value / messageStats.totalMessages) *
											100,
									)}%)`
								}
								colors={["green-500", "red-600"]}
								includeLegend={true}
							/>
						</div>
					</TabPanel>
					<TabPanel>
						<div className="mt-4">
							<DonutChart
								data={channelData}
								category="value"
								index="name"
								variant="pie"
								valueFormatter={(value) =>
									`${value}x (${Math.round(
										(value / messageStats.totalMessages) *
											100,
									)}%)`
								}
								colors={["blue-500", "purple-500"]}
								includeLegend={true}
							/>
						</div>
					</TabPanel>
				</TabPanels>
			</TabGroup>
		</div>
	);
};

export default MessageStatistics;
